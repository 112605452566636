.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.recent-posts .title{
  display: none;
}

.founder-cardlayout{
  background: #89CFF0;
  background: linear-gradient(131deg, rgba(172,247,0,0.8470238437171744) 0%, rgba(43,199,252,1) 50%, rgba(65,247,226,1) 100%);
}

.vission-sec{
  background-image: url("./assets//img/about-2.jpg");
  background-color: #cccccc;
}

/*Marquee effect*/
.marquee-text {
  overflow: clip;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;

}

.marquee-text-track {
  padding-left: 1.8rem;
  gap: 1rem;
  width: max-content;
  /*animation: marquee-move-text var(--speed, 10s) linear infinite var(
      --direction,
      forwards
    ); */
}

.marquee-text p {
  border: 1px solid white;
  background-color: #141414;
  border-radius: 999px;
  padding: 1rem 2.5rem;
  color: aqua !important;
  cursor: pointer;
  text-decoration: underline;
}

.navbar{
  display: none;
}

@keyframes marquee-move-text {
  to {
    transform: translateX(-50%);
  }
}


/* marquee effect ends */


.acc-header button{
  background-color: #01433c !important;
  color: aqua !important;
}
.accordion-button:not(.collapsed)::after {
  background-color: aqua !important;
}
.accordion-button::after {
  
  background-color: aqua !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
