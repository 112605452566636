/* Marquee styles */
.marquee {
  --gap: 2rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.marquee__content {
  display: flex;
  gap: var(--gap);
  white-space: nowrap;
 /* animation-timing-function: cubic-bezier(0.25, 1, 0.5, 1);  Smooth easing */
}

.marquee__content > li {
  flex: 0 0 auto;
}


@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap) - 1px)); /* Add small buffer to avoid flicker */
  }
}

@media (prefers-reduced-motion: reduce) {
  .marquee__content {
    animation-play-state: paused !important;
  }
}

/* Enable animation */
.enable-animation .marquee__content {
  animation: scroll 30s linear infinite;
}

/* Pause on hover */
.marquee--hover-pause:hover .marquee__content {
  animation-play-state: paused;
}






/* Other page demo styles */
.marquee__content > * {
  flex: 0 0 auto;
  color: white;
  background: dodgerblue;
  margin: 2px;
  padding: .25rem 1rem;
  border-radius: 0.5rem;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

/*.marquee {
  border: 2px dashed lightgray;
}*/
