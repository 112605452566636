
    .gallery-widget {
      background-color: #f1f1f1;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      overflow: hidden;
      height: 360px;
    }

    .video-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr); /* 3 columns */
        gap: 20px;
      }
      

    .gallery-title {
      text-align: center;
      color: green;
      font-size: 20px;
      margin-bottom: 5px;
      height: 40px;
    }

    .gallery-container {
      position: relative;
      width: 100%;
      height: auto;
      overflow: hidden;
    }

    .gallery-item {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .gallery-image {
      max-width: 100%;
      max-height: 100%;
      object-fit: fill;
      border-radius: 10px;
      transition: transform 0.5s ease;
    }

    .gallery-image:hover {
      transform: scale(1.05);
      cursor: pointer;
    }

    /* Modal Styles */
    .modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10009999;
    }

    .modal-content {
      background-color: white;
      padding: 20px;
      border-radius: 10px;
      position: relative;
      align-items: center;
    }

    .modal-image {
      width: 100%;
      max-width: 800px;
      border-radius: 10px;
      object-fit: contain;
      transition: transform 0.5s ease;
    }

    .modal-image:hover {
      transform: scale(1.1);
    }

    .modal-nav {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
    }

    .modal-nav-btn {
      background-color: #f44336;
      color: white;
      border: none;
      padding: 10px;
      cursor: pointer;
      border-radius: 5px;
      font-size: 16px;
    }

    .modal-nav-btn:hover {
      background-color: #d32f2f;
    }

    .modal-close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: #f44336;
      color: white;
      border: none;
      padding: 10px;
      cursor: pointer;
      border-radius: 50%;
      font-size: 18px;
    }

    .modal-close-btn:hover {
      background-color: #d32f2f;
    }

    .project-info {
      display: flex;
      justify-content: space-between; /* Pushes content to both ends */
      align-items: center;
      padding: 10px;
      font-size: 1rem;
      color: #34495e;
      background-color: #f9f9f9;
      border: 1px solid #ddd;
      border-radius: 8px;
    }
    
    .project-date {
      font-weight: 600;
    }
    
    .project-photos {
      font-weight: 600;
    }
    
