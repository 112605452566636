/* General styling */
  
  /* Container for the cards */
  .containerCards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 24px;
  }
  
  /* Individual college card with glassmorphism effect */
  .college-card {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 16px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .college-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  }
  
  /* College logo */
  .college-logo {
    width: 50%;
    height:150px;
    object-fit: contain;
    background-color: white;
  }
  
  /* College details */
  .college-details {
    padding: 20px;
  }
  
  .college-name {
    font-size: 1rem;
    color: #2c3e50;
    margin-bottom: 8px;
    font-weight: 700;
    height:100px;
  }
  
  .college-address,
  .college-state {
    font-size: 1rem;
    color: #34495e;
    margin-bottom: 6px;
  }
  
  /* Website button with gradient and hover effect */
  .college-website {
    display: inline-block;
    padding: 10px 10px;
    margin-top: 8px;
    background: linear-gradient(90deg, #007bff, #0056b3);
    color: #ffffff;
    text-decoration: none;
    border-radius: 50px;
    font-size: 1rem;
    font-weight: 600;
    transition: background 0.3s ease;
  }
  
  .college-website:hover {
    background: linear-gradient(90deg, #0056b3, #003f7f);
  }
  
/* Button container at the bottom */
.card-footer {
  margin-top: auto; /* Pushes the button to the bottom */
}

  /* Responsive adjustments */
  @media (max-width: 600px) {
    .college-name {
      font-size: 1.2rem;
    }
  
    .college-address,
    .college-state {
      font-size: 0.9rem;
    }
  
    .college-website {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  